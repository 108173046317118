import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface SimData {
  SimulationID: string;
  IncidentName: string;
}

@Component({
  selector: 'app-sim-select',
  templateUrl: './sim-select.component.html',
  styleUrls: ['./sim-select.component.css']
})
export class SimSelectComponent {
  public selected = null;

  constructor(
    public dialogRef: MatDialogRef<SimSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<SimData>) {
      dialogRef.disableClose = true;
    }
  

}
