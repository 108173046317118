import { Injectable } from '@angular/core';
import FormTemplate from '@arcgis/core/form/FormTemplate';


@Injectable({
  providedIn: 'root'
})
export class BarrierFormService {
  private aircraftExpr = {
    name: 'AircraftOnly',
    expression: "$feature.Type == 'Retardant' || $feature.Type == 'Water'"
  }
  private aircraftIDFldElement = {
    type: 'field',
    fieldName: 'Aircraft_ID',
    label: 'Identifier',
  }
  private typeFldElement = {
    type: 'field',
    fieldName: 'Type',
    label: 'Type'
  }
  private dropTimeFldElement = {
    type: 'field',
    fieldName: 'Drop_time',
    label: 'Drop Time',
    visibilityExpression: 'AircraftOnly'
  }
  private startTimeFldElement = {
    type: 'field',
    fieldName: 'Drop_time',
    label: 'Start Time',
  }
  private dropHeightFldElement = {
    type: 'field',
    fieldName: 'Drop_height',
    label: 'Drop Height',
    visibilityExpression: 'AircraftOnly'
  }

  private coverageLevelFldElement = {
    type: 'field',
    fieldName: 'Coverage_level',
    label: 'Coverage Level',
    visibilityExpression: 'AircraftOnly'
  }
 
  private _barrierFormTemplate = new FormTemplate({
    expressionInfos: [
      this.aircraftExpr
    ],
    elements: [
      this.aircraftIDFldElement, 
      this.startTimeFldElement,
      this.dropHeightFldElement, 
      this.coverageLevelFldElement,
      this.typeFldElement,
    ]
  })
  get barrierFormTemplate(): FormTemplate {
    return this._barrierFormTemplate;
  }
  constructor() { }
}
