import { Injectable } from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {
  aircraftPopup = new PopupTemplate({
    title: 'Aircraft {Callsign}',
    // content: "A {Model} heading at {Heading_deg} degrees azimuth"
    // Alternate content format
    content: [{
      type: 'fields',
      fieldInfos: [{
        fieldName: 'Callsign',
        label: 'Callsign'
      }, {
        fieldName: 'Model',
        label: 'Model'
      },
      {
        fieldName: 'Client_type',
        label: 'Client Type'
      },
      {
        fieldName: 'Client_names',
        label: 'Name of person(s) operating the aircraft'
      },
      {
        fieldName: 'Altitude_msl_ft',
        label: 'Altitude (MSL) (ft)'
      },
      {
        fieldName: 'IAS_kts',
        label: 'Aircraft indicated airspeed (kts)'
      },
      {
        fieldName: 'Heading_deg',
        label: 'True heading (deg)'
      },
      {
        fieldName: 'Drop_controller_state',
        label: 'Drop Controller State'
      }
    ]
    }]
  });

  private c130Symbol = new PictureMarkerSymbol({
    url: './assets/images/c130.png',
    height: 30,
    width: 30,
  })
  
  private ac690Symbol = new PictureMarkerSymbol({
    url: './assets/images/ac_690.png',
    height: 30,
    width: 40,
  })

  private kingAirSymbol = new PictureMarkerSymbol({
    url: './assets/images/king_air.png',
    height: 30,
    width: 40,
  })

  private rj85Symbol = new PictureMarkerSymbol({
    url: './assets/images/rj85.png',
    height: 30,
    width: 45,
  })

  private s70Symbol = new PictureMarkerSymbol({
    url: './assets/images/s70.png',
    height: 30,
    width: 45,
  })

  private cl415Symbol = new PictureMarkerSymbol({
    url: './assets/images/cl_415.png',
    height: 30,
    width: 40,
  })
  
  private airTractorSymbol = new PictureMarkerSymbol({
    url: './assets/images/at802at.png',
    height: 30,
    width: 35
  })

  private fireBossSymbol = new PictureMarkerSymbol({
    url: './assets/images/at802fb.png',
    height: 30,
    width: 35
  })

  private genericSymbol = new PictureMarkerSymbol({
    url: './assets/images/generic_aircraft.png',
    height: 30,
    width: 30
  })

  private greenBCSymbol = new SimpleMarkerSymbol({
    outline: { width: 0 },
    size: 6.5,
    color: [121, 243, 71, 1]
  })

  private redBCSymbol = new SimpleMarkerSymbol({
    outline: { width: 0 },
    size: 6.5,
    color: [245, 11, 11, 1]
  })

  private blueBCSymbol = new SimpleMarkerSymbol({
    outline: { width: 0 },
    size: 6.5,
    color: [21, 28, 195, 1]
  })
  
  private yellowBCSymbol = new SimpleMarkerSymbol({
    outline: { width: 0 },
    size: 6.5,
    color: [250, 212, 30, 1]
  })

  private rotation = {
    type: 'rotation', // indicates that symbols should be rotated based on value in field
    field: 'Heading_deg', // field containing aspect values
    rotationType: 'geographic'
  }

  // var symbol3D = new WebStyleSymbol({
    //   name: "Airplane_Large_Passenger",
    //   portal: { url: "https://www.arcgis.com" },
    //   styleName: "EsriRealisticTransportationStyle",
    // });

    // var renderer3D = new UniqueValueRenderer({
    //   valueExpression: `When($feature.Model == 'C-130H MAFFS' || $feature.Model == 'C130H MAFFS'|| $feature.Model == 'C-130H', 'C-130',
    //                          'NA')`,
    //   uniqueValueInfos: [{
    //     label: 'C-130',
    //     value:'C-130',
    //     symbol: symbol3D
    //   }
    // ],
    // visualVariables: [rotation]
    // });
    
    // symbol3D.fetchSymbol().then((pointSymbol3D) => {
    //   // clone the retrieved symbol, as properties on it are immutable
    //   const newSymbol = pointSymbol3D.clone();
    //   // the first symbolLayer contains the properties that can be modified
    //   const symbolLayer: any = newSymbol.symbolLayers.getItemAt(0);
    //   // change for example the height of the object
    //   symbolLayer.depth = 50;
    //   symbolLayer.height = 50;
    //   symbolLayer.width = 50;
    //   symbolLayer.depth = 50;
    //   symbolLayer.castShadows = true;
    //   // do something with newSymbol, like setting it on a layer renderer
    //   const newRenderer = renderer3D.clone();
    //   newRenderer.symbol = newSymbol;
    //   renderer3D = newRenderer;
    // });

  constructor() { }

  private createLocationRenderer(): UniqueValueRenderer {
    const renderer = new UniqueValueRenderer({
      valueExpression: `When($feature.Model == 'C-130H MAFFS' || $feature.Model == 'C130H MAFFS'|| $feature.Model == 'C-130H', 'C-130',
                             $feature.Model == 'Avro RJ85' || $feature.Model == 'British Aerospace BAe146', 'RJ85',
                             $feature.Model == 'Beechcraft C90 King Air' || $feature.Model == 'Beechcraft 200 King Air', 'King Air',
                             $feature.Model == 'Aero Commander 690' || $feature.Model == 'Aero Commander 500', 'Aero Commander',
                             $feature.Model == 'Sikorsky S70', 'Sikorsky S70',
                             $feature.Model == 'Bombardier CL-415' || $feature.Model == 'CL-415', 'Bombardier CL-415',
                             $feature.Model == 'AT-802 Air Tractor', 'AT-802 Air Tractor',
                             $feature.Model == 'AT-802 Fire Boss', 'AT-802 Fire Boss',
                             'NA')`,
      uniqueValueInfos: [{
        label: 'C-130',
        value:'C-130',
        symbol: this.c130Symbol
      },
      {
        label: 'RJ85',
        value:'RJ85',
        symbol: this.rj85Symbol
      },
      {
        label: 'King Air',
        value:'King Air',
        symbol: this.kingAirSymbol
      },
      {
        label: 'Aero Commander',
        value:'Aero Commander',
        symbol: this.ac690Symbol
      },
      {
        label: 'Sikorsky S70',
        value:'Sikorsky S70',
        symbol: this.s70Symbol
      },
      {
        label: 'Bombardier CL-415',
        value:'Bombardier CL-415',
        symbol: this.cl415Symbol
      },
      {
        label: 'AT-802 Air Tractor',
        value: 'AT-802 Air Tractor',
        symbol: this.airTractorSymbol
      },
      {
        label: 'AT-802 Fire Boss',
        value: 'AT-802 Fire Boss',
        symbol: this.fireBossSymbol
      },
      {
        label: 'Unknown',
        value: 'NA',
        symbol: this.genericSymbol
      }
    ],
      visualVariables: [this.rotation]
    });

    return renderer;
  }

  private createBreadCrumbRenderer(): UniqueValueRenderer {
    const renderer = new UniqueValueRenderer({
      valueExpression: `When($feature.Model == 'C-130H MAFFS' || $feature.Model == 'C130H MAFFS'|| $feature.Model == 'C-130H' ||
                                                $feature.Model == 'Avro RJ85' || $feature.Model == 'British Aerospace BAe146' || 
                                                $feature.Model == 'Bombardier CL-415' || $feature.Model == 'CL-415' ||
                                                $feature.Model == 'AT-802 Air Tractor' || $feature.Model == 'AT-802 Fire Boss', 'green',
                             $feature.Model == 'Beechcraft C90 King Air' || $feature.Model == 'Beechcraft 200 King Air' || 
                                                $feature.Model == 'Aero Commander 690' || $feature.Model == 'Aero Commander 500', 'red',
                             $feature.Model == 'Sikorsky S70', 'blue',
                             'NA')`,
      uniqueValueInfos: [{
        label: 'green',
        value:'green',
        symbol: this.greenBCSymbol
      },
      {
        label: 'red',
        value:'red',
        symbol: this.redBCSymbol
      },
      {
        label: 'blue',
        value:'blue',
        symbol: this.blueBCSymbol
      },
      {
        label: 'yellow',
        value: 'NA',
        symbol: this.yellowBCSymbol
      }
    ]
    });

    return renderer;
  }

  createAircraftLayer(type: string): FeatureLayer {
    let renderer;
    let aircraft;
    switch (type) {
      case 'location': {
        renderer = this.createLocationRenderer();
        aircraft = new FeatureLayer({
          url: 'https://services3.arcgis.com/xpVaCPCktnbBXniZ/arcgis/rest/services/aircraftlocation/FeatureServer/0',
          title: 'Aircraft Location',
          visible: false,
          labelsVisible: true,
          refreshInterval: 0.05,
          editingEnabled: false,
          popupEnabled: true,
          popupTemplate: this.aircraftPopup,
          effect: "drop-shadow(8px, 8px, 2px)",
          renderer: renderer,
          // renderer: renderer3D,
          elevationInfo: {
            mode: "relative-to-scene",
            featureExpressionInfo: {
              expression: "$feature.Altitude_AGL_ft"
            },
            unit: "feet"
          }
        });
        break;
      } 
      case 'breadcrumb': {
        renderer = this.createBreadCrumbRenderer();
        aircraft = new FeatureLayer({
          url: 'https://services3.arcgis.com/xpVaCPCktnbBXniZ/arcgis/rest/services/aircraftlocation/FeatureServer/0',
          visible: false,
          legendEnabled: false,
          refreshInterval: 0.05,
          effect: "drop-shadow(2px, 2px, 1px)",
          renderer: renderer
        })
      }
      break;
    }
    return aircraft;
  }
}
