import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';

@Injectable({
  providedIn: 'root'
})
export class MapConfigService {
  mapConfig = {
    mapView: null,
    sceneView: null,
    activeView: null,
    container: null
  }
  viewLabel = '';
  private map = new Map({
    basemap: 'arcgis-topographic',
    ground: 'world-elevation'
  })
  private viewTypeSource = new Subject<string>();
  viewType$ = this.viewTypeSource.asObservable();

  constructor() { }

  createView(params, type, activeView: boolean) {
    if (!!params.container) {
      this.mapConfig.container = params.container;
    }
    if (type === '2d') {
      this.mapConfig.mapView = new MapView(params);
      this.mapConfig.mapView.map = this.map;
      if (activeView) {
        this.viewLabel = '3D';
        this.mapConfig.activeView = this.mapConfig.mapView;
        this.viewTypeSource.next('2D');
      }
    } else {
      this.mapConfig.sceneView = new SceneView(params);
      this.mapConfig.sceneView.map = this.map;
      if (activeView) {
        this.viewLabel = '2D';
        this.mapConfig.activeView = this.mapConfig.sceneView;
        this.viewTypeSource.next('3D');
      }
    }
  }

  setMapLayers(layers) {
    this.map.layers = layers;
  }

  switchMapView() {
    const is3D = this.mapConfig.activeView.type === '3d';
    const activeViewpoint = this.mapConfig.activeView.viewpoint.clone();

    // remove the reference to the container for the previous view
    this.mapConfig.activeView.container = null;

    if (is3D) {
      // if the input view is a SceneView, set the viewpoint on the
      // mapView instance. Set the container on the mapView and flag
      // it as the active view
      // set the viewLabel as 3D and set the observable viewtype as 2D
      this.mapConfig.mapView.viewpoint = activeViewpoint;
      this.mapConfig.mapView.container = this.mapConfig.container;
      this.mapConfig.activeView = this.mapConfig.mapView;
      this.viewLabel = '3D';
      this.viewTypeSource.next('2D');
    } else {
      this.mapConfig.sceneView.viewpoint = activeViewpoint;
      this.mapConfig.sceneView.container = this.mapConfig.container;
      this.mapConfig.activeView = this.mapConfig.sceneView;
      this.viewLabel = '2D';
      this.viewTypeSource.next('3D');
    }
      
  }
    
}
