<h1>Select a Simulation</h1>
<div mat-dialog-content>
    <mat-form-field>
      <mat-label>Simulation</mat-label>
      <mat-select [(value)]="selected">
          <mat-option *ngFor="let sim of data" [value]="sim.Simulation_ID">
              {{sim.Simulation_name}}
          </mat-option>
      </mat-select>
      <!-- <input matInput [(ngModel)]="data.name"/> -->
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="selected" cdkFocusInitial [disabled]="selected === null">Ok</button>
    <!-- <button mat-button [mat-dialog-close]="data.name" cdkFocusInitial>Ok</button> -->
  </div>
