import { BarrierFormService } from './../../services/barrier-form.service';
import { SimSelectComponent } from './sim-select/sim-select.component';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  NgZone
} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import TileLayer from '@arcgis/core/layers/TileLayer';
import MapView from '@arcgis/core/views/MapView';
import esriConfig from '@arcgis/core/config.js';
import Legend from '@arcgis/core/widgets/Legend';
import Compass from '@arcgis/core/widgets/Compass';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import Slider from '@arcgis/core/widgets/Slider';
import Expand from '@arcgis/core/widgets/Expand';
import Editor from '@arcgis/core/widgets/Editor';
import Query from '@arcgis/core/rest/support/Query';
import QueryTask from '@arcgis/core/tasks/QueryTask';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import { MapConfigService } from 'src/app/services/map-config.service';
import { AircraftService } from 'src/app/services/aircraft.service';
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  private simulations = [];
  private simulationId: number = null;
  private curMinute: number = 1;
  private sliderMinute: number = 1;
  private liveMode = false;
  private viewer = 'facilitator' //facilitator is checked in the template
  private view: MapView = null;
  private perimeters: FeatureLayer = null;
  private perimetersView;
  private firePoints: FeatureLayer = null;
  private firePointsView;
  private ignitionsUrl: string = 'https://services3.arcgis.com/xpVaCPCktnbBXniZ/arcgis/rest/services/ignitionpoints/FeatureServer/0';
  private ignitions: FeatureLayer = null;
  private barriers: FeatureLayer = null;
  private aircraftLoc: FeatureLayer = null;
  private aircraftLocView;
  private aircraftBreadCrumbLayer: FeatureLayer = null;
  private aircraftBreadCrumbView;
  private editorWidget: Editor = null;
  private expandWidget: Expand = null;
  private slider: Slider = null;
  public fbfm40Visible: boolean = false;
  private fbfm40Layer: MapImageLayer = null;
  public airspaceMapVisible: boolean = false;
  private airspaceMapLayer: TileLayer = null;
  public fbfm40Hidden: boolean = false;
  public airspaceMapHidden: boolean = false;
  public sliderHidden: boolean = false;
  public elapsedMinute: string = '1';
  public acreage: number = 0;
  public viewTypeLabel: string = '3D';
  public nextBaseMap: string = 'arcgis-imagery'
  private pointerHandler = null;


  // The <div> where we will place the map
  @ViewChild('mapViewNode', { static: true }) private mapViewEl: ElementRef;
  @ViewChild('sliderContainerNode', { static: true }) private sliderContainerEl: ElementRef;
  @ViewChild('sliderNode', { static: true }) private sliderEl: ElementRef;
  @ViewChild('fbfm40Node', { static: true }) private fbfm40El: ElementRef;
  @ViewChild('airspaceMapNode', { static: true }) private airspaceEl: ElementRef;
  @ViewChild('statisticsNode', { static: true }) private statisticsEl: ElementRef;
  @ViewChild('view3DSwitchNode', { static: true }) private view3DSwitchEl: ElementRef;
  @ViewChild('view2DSwitchNode', { static: true }) private view2DSwitchEl: ElementRef;

  title = 'FS3D';

  constructor(private zone: NgZone, public dialog: MatDialog, public barrierFormService: BarrierFormService, 
              public mapConfigService: MapConfigService, public aircraftService: AircraftService) {
    mapConfigService.viewType$.subscribe(
      viewType => {
        this.viewTypeLabel = viewType === '2D' ? '3D' : '2D';
        this.buildWidgetsTools(viewType);
        this.createLayerViews();
      }
    )
   }

  initializeMap(): Promise<any> {
    esriConfig.apiKey = 'AAPKe63083eb6bda4f348c54d9c7a2138ff7Jv6vQ-lZn3ANvL-nWOscpRr3A-A0SnwS6Iwtm_H1BZ4-MfnNvhf1AH-I-oF_iBz5';
    

    // Perimeters layer
    this.perimeters = new FeatureLayer({
      url: 'https://services3.arcgis.com/xpVaCPCktnbBXniZ/arcgis/rest/services/Perimeters2/FeatureServer/0',
      title: 'Fire Perimeters',
      visible: false,
      //refreshInterval: 1,
      editingEnabled: false,
      popupEnabled: false,
      //effect: 'blur(3px)'
    });

    // Fire Points layer
    this.firePoints = new FeatureLayer({
      url: 'https://services3.arcgis.com/xpVaCPCktnbBXniZ/arcgis/rest/services/FirePoints/FeatureServer/0',
      title: 'Fireline Rate of spread (chains/hr)',
      visible: false,
      editingEnabled: false,
      popupEnabled: false,
      effect: 'bloom(2.5, 0.7px, 0.5)'
    })
    
    // Ignitions layer
    const ignitionPopup = new PopupTemplate({
      title: '{Simulation_name}',
      content: 'Started on {Start_time}'
    });
    this.ignitions = new FeatureLayer({
      url: this.ignitionsUrl,
      title: 'Ignition Point',
      visible: false,
      editingEnabled: false,
      popupEnabled: true,
      popupTemplate: ignitionPopup
    });

    // Barrier layer
    const barrierPopup = new PopupTemplate({
      title: '{Type} Line',
      content: [{
        type:'fields',
        fieldInfos: [{
          fieldName: 'Drop_time',
          label: 'Drop Time'
        }, {
          fieldName: 'Drop_height',
          label: 'Drop Height'
        }, {
          fieldName: 'Drop_heading_az',
          label: 'Drop Heading (Az)'
        }, {
          fieldName: 'Drop_volume_gal',
          label: 'Drop Volume (Gal)'
        }, {
          fieldName: 'Coverage_level',
          label: 'Coverage Level'
        }, {
          fieldName: 'Source'
        }]
      }]
    })
    this.barriers = new FeatureLayer({
      url: 'https://services3.arcgis.com/xpVaCPCktnbBXniZ/arcgis/rest/services/Barriers2/FeatureServer/0',
      title: 'Fire Barriers',
      visible: false,
      refreshInterval: 1,
      formTemplate: this.barrierFormService.barrierFormTemplate,
      popupEnabled: true,
      popupTemplate: barrierPopup
    });

    // Aircraft layer
    this.aircraftLoc = this.aircraftService.createAircraftLayer('location');
    this.aircraftBreadCrumbLayer = this.aircraftService.createAircraftLayer('breadcrumb');
    
    // Fuel model layer
    this.fbfm40Layer = new MapImageLayer({
      url: 'https://landfire.cr.usgs.gov/arcgis/rest/services/Landfire/US_200/MapServer',
      visible: this.fbfm40Visible,
      opacity: 0.6,
      sublayers: [{ id: 12 }],
      title: 'Landfire Fire Behavior Fuel Model 40'
    })

    this.airspaceMapLayer = new TileLayer({
      url: 'https://tiles.arcgis.com/tiles/BLN4oKB0N1YSgvY8/arcgis/rest/services/LosAngeles_FAASectionalChart_December2021/MapServer',
      visible: this.airspaceMapVisible,
      opacity: 0.6,
      title: 'Landfire Fire Behavior Fuel Model 40'
    })

    // Create map layers array and add it to the map
    const mapLayers = [this.airspaceMapLayer, this.fbfm40Layer, this.perimeters, this.firePoints, 
      this.barriers, this.ignitions, this.aircraftBreadCrumbLayer, this.aircraftLoc
     ]
    this.mapConfigService.setMapLayers(mapLayers);

    // Setup Map and MapView
    // Map parameters
    const initialViewParams = {
      zoom: 4,
      center: [-98,38],
      container: this.mapViewEl.nativeElement,
    }

    // Create initial 2d mapView
    this.mapConfigService.createView(initialViewParams, '2d', true);

    // Create a 3d view, won't initialize until container is set
    initialViewParams.container = null;
    this.mapConfigService.createView(initialViewParams, '3d', false);

    // this.view = view;
    this.view = this.mapConfigService.mapConfig.activeView;
    return this.view.when();
  }

  createLayerViews() {
    // FirePoints LayerView
    this.firePoints.visible = (this.mapConfigService.mapConfig.activeView.type === '3d') ? false : true; 
    this.mapConfigService.mapConfig.activeView.whenLayerView(this.firePoints).then(layerView => {
      this.firePointsView = layerView;
      if (this.viewer === 'facilitator') {
        this.firePointsView.filter = {
          where: 'ET_mins = ' + this.sliderMinute
        }
      } else {
        this.firePointsView.filter = {
          where: 'ET_mins = ' + this.curMinute
        }
      }
    })

    // Perimeter LayerView
    this.mapConfigService.mapConfig.activeView.whenLayerView(this.perimeters)
    .then(layerView => {
      this.perimetersView = layerView;
      if (this.viewer === 'facilitator') {
        this.perimetersView.filter = {
          where: 'ET_mins = ' + this.sliderMinute
        }
      } else {
        this.perimetersView.filter = {
          where: 'ET_mins = ' + this.curMinute
        }
      }
    })

    // AircraftLoc LayerView
    this.mapConfigService.mapConfig.activeView.whenLayerView(this.aircraftLoc)
    .then(layerView => {
      this.aircraftLocView = layerView;
      if (this.viewer === 'facilitator') {
        this.aircraftLocView.filter = {
          where: 'ET_mins = ' + this.sliderMinute
        }
      } else {
        this.aircraftLocView.filter = {
          where: 'currentAC = 1'
        }
      }
      
      // Aircraftbreadcrumb LayerView
      this.mapConfigService.mapConfig.activeView.whenLayerView(this.aircraftBreadCrumbLayer)
      .then(layerView => {
        this.aircraftBreadCrumbView = layerView;
        if (this.liveMode) {
          this.aircraftLocView.filter = {
            where: 'currentAC = 1'
          }
          this.aircraftBreadCrumbView.filter = {
            where: 'currentAC = 0'
          }
        } else {
          this.aircraftLocView.filter = {
            where: 'ET_mins = ' + this.sliderMinute
          }
          this.aircraftBreadCrumbView.filter = {
            where: 'ET_mins < ' + this.sliderMinute
          }
        }
      })

      // Add the breadcrumb visibility only if the view is 2D
      // First remove the handler for the pointer if it exists
      if (this.pointerHandler) {
        this.pointerHandler.remove();
        this.pointerHandler = null;
      };
      // If the view is 2D than create the pointer handler and add the breadcrumb functionality
      if (this.mapConfigService.mapConfig.activeView.type === '2d') {
        this.pointerHandler = this.mapConfigService.mapConfig.mapView.on('pointer-move', event => {
          let point = this.mapConfigService.mapConfig.mapView.toMap({x: event.x, y: event.y});
          // Buffer the screen point at 100 meters
          let buffer = geometryEngine.buffer([point], 200, 'meters');
          this.aircraftLocView.queryFeatures().then(results => {
            // For each result check if it intersects with the cursor buffer.  Return true
            // for the first feature it intersects
            const showBreadCrumb = results.features.some(loc => {
              const intersects = geometryEngine.intersects(buffer[0], loc.geometry);
              return intersects == true;
            })
            // Show the breadcrumb trail layer if the cursor intersects with any aircraft,
            // otherwise don't show it.
            if (showBreadCrumb) {
              this.aircraftBreadCrumbLayer.visible = true;
              
            } else {
              this.aircraftBreadCrumbLayer.visible = false;
            }
          })
        })
      }
    });
  }

  buildWidgetsTools(viewType: string) {

    // ************************* top-left UI elements *********************************************
    // Compass
    const compass = new Compass({
      view: this.mapConfigService.mapConfig.activeView,
      id: 'myCompass'
    });
    const compassFind = this.mapConfigService.mapConfig.activeView.ui.find('myCompass');
    if (!!compassFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(compassFind);
    }
    if (viewType === '2D') {
      this.mapConfigService.mapConfig.activeView.ui.add(compass, 'top-left');
    }

    // Add the 2D/3D view switcher button
    const viewTypeSwitchFind = this.mapConfigService.mapConfig.activeView.ui.find(this.view3DSwitchEl.nativeElement);
    if (!!viewTypeSwitchFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(this.view3DSwitchEl.nativeElement);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(this.view3DSwitchEl.nativeElement, 'top-left');

    // Editor/Expander
    if (!!this.editorWidget) {
      this.editorWidget.destroy();
    }
    this.editorWidget = new Editor({
      view: this.mapConfigService.mapConfig.activeView,
      allowedWorkflows: ['create'],
    });
    this.expandWidget = new Expand({
      view: this.mapConfigService.mapConfig.activeView,
      content: this.editorWidget,
      id: 'myExpand'
    })
    const expandFind = this.mapConfigService.mapConfig.activeView.ui.find('myExpand');
    if (!!expandFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(expandFind);
    }
    if (this.viewer === 'facilitator' || this.viewer === 'incident-commander') {
      this.mapConfigService.mapConfig.activeView.ui.add(this.expandWidget, 'top-left');
    }

    // ************************ top-right UI elements *********************************************
    // Legend
    const legend = new Legend({
      view: this.mapConfigService.mapConfig.activeView,
      id: 'myLegend'
    })
    const legendFind = this.mapConfigService.mapConfig.activeView.ui.find('myLegend');
    if (!!legendFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(legendFind);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(legend, 'top-right');

    // Slider
    if (!this.slider) {
      this.slider = new Slider({
        container: this.sliderEl.nativeElement,
        min: 1,
        max: 60,
        steps: 1,
        visibleElements: {
          labels: true,
        },
        precision: 0,
        labelFormatFunction: function(value, type) {
          return value.toString() + "min";
        },
        values: [1],
        id: 'mySlider'
      });
    }
    // Add the slider functionality
    this.slider.on("thumb-drag", event => {
      this.filterPerimeters(event.value);
    });
    const sliderFind = this.mapConfigService.mapConfig.activeView.ui.find('mySlider');
    if (!!sliderFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(sliderFind);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(this.sliderContainerEl.nativeElement,'top-right');
    
    // *************************** bottom-left UI elements ****************************************
    // Scalebar
    const scaleBar = new ScaleBar({
      view: this.mapConfigService.mapConfig.activeView,
      unit: 'dual',
      id: 'myScaleBar'
    })
    const scaleBarFind = this.mapConfigService.mapConfig.activeView.ui.find('myScaleBar');
    if (!!scaleBarFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(scaleBarFind);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(scaleBar, 'bottom-left');

    // *************************** bottom-right UI elements ****************************************
    // Basemap Toggle
    // Determine if world imagery is in current list of all layers
    const worldImagery = this.mapConfigService.mapConfig.activeView.map.allLayers.find(layer => {
      return layer.title === 'World Imagery';
    })
    // If world imagery is in current list of all layers than it is the current basemap.  
    // Make the topo map the next basemap for the toggle.  If world imagery is not the current 
    // basemap than make imagery the next basemap for the toggle.
    this.nextBaseMap = worldImagery ? 'arcgis-topographic' : 'arcgis-imagery';
    const basemapToggle = new BasemapToggle({
      view: this.mapConfigService.mapConfig.activeView,
      nextBasemap: this.nextBaseMap,
      id: 'myBasemapToggle'
    })
    const basemapFind = this.mapConfigService.mapConfig.activeView.ui.find('myBasemapToggle');
    if (!!basemapFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(basemapFind);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(basemapToggle, 'bottom-right');

    // Layer Buttons
    const fbfmFind = this.mapConfigService.mapConfig.activeView.ui.find(this.fbfm40El.nativeElement);
    if (!!fbfmFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(this.fbfm40El.nativeElement);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(this.fbfm40El.nativeElement, 'bottom-right');
    const airspaceFind = this.mapConfigService.mapConfig.activeView.ui.find(this.airspaceEl.nativeElement);
    if (!!airspaceFind) {
      this.mapConfigService.mapConfig.activeView.ui.remove(this.airspaceEl.nativeElement);
    }
    this.mapConfigService.mapConfig.activeView.ui.add(this.airspaceEl.nativeElement, 'bottom-right');
  }

  openDialog(): void {
    this.getSimulationIds().then(sims =>{
      const dialogRef = this.dialog.open(SimSelectComponent, {
        width: '250px',
        data: sims
      });

      dialogRef.afterClosed().subscribe(result => {
        this.simulationId = result;
        console.log(this.simulationId);

        // Setup Ignitions
        this.ignitions.when(() => {
          this.ignitions.definitionExpression = "Simulation_ID = '" + this.simulationId.toString() + "'";
          this.ignitions.queryExtent().then(results =>{
            const extent = results.extent;
            this.view.goTo({
              center: extent.center,
              zoom: 15
            });
          })
          this.ignitions.visible = true;
        }).then(() => {
          console.log("def expression")
        })

        // Setup Barriers
        this.barriers.when(() => {
          this.barriers.definitionExpression = "Simulation_ID = '" + this.simulationId.toString() + "'";
          this.barriers.visible = true;
        })
        this.barriers.on('edits', edits => {
          const results: any = edits;
          if (results.addedFeatures.length > 0 ) {
            results.edits.addFeatures[0].attributes.OBJECTID = results.addedFeatures[0].objectId;
            results.edits.addFeatures[0].attributes.Simulation_ID = this.simulationId.toString();
            this.barriers.applyEdits({
              updateFeatures: results.edits.addFeatures
            });
          }
        })

        // Setup Fire Points
        this.firePoints.when(() => {
          this.firePoints.definitionExpression = "Simulation_ID = '" + this.simulationId.toString() + "'";
          this.firePoints.visible = true;
        })

        // Setup Perimeters
        this.perimeters.when(() => {
          this.perimeters.definitionExpression = "Simulation_ID = '" + this.simulationId.toString() + "'";
          this.perimeters.visible = true;
          this.getAcreage(1);
          // TODO try moving the livMode check to the queryCurrentMin method, if curMinute is greater than 60 then change slider values
          // setInterval(() => { if(this.liveMode) this.queryCurrentMin(); }, 60*1000);
          setInterval(() => {this.queryCurrentMin()}, 60*1000);
        })

        // Setup Aircraft Locations
        this.aircraftLoc.when(() => {
          this.aircraftLoc.definitionExpression = "Simulation_ID = '" + this.simulationId.toString() + "'";
          this.aircraftLoc.visible = true;
        })

        // Setup Aircraft breadcrumb
        this.aircraftBreadCrumbLayer.when(() => {
          this.aircraftBreadCrumbLayer.definitionExpression = "Simulation_ID = '" + this.simulationId.toString() + "'" + " AND currentAC = 0";
        })

        // Get the current minute
        this.queryCurrentMin();
      });
    });
  }

  getSimulationIds(): Promise<any> {
    const queryTask = new QueryTask({
      url: this.ignitionsUrl
    });
    const query = new Query();
    query .returnGeometry = false;
    query.outFields = ['Simulation_name', 'Simulation_ID'];
    query.where = '1=1';
    query.returnDistinctValues = true;

    queryTask.execute(query).then(results => {
      results.features.forEach(sim => {
        this.simulations.push(sim.attributes)
      })
    })

    return new Promise((resolve, reject) => {
      resolve(this.simulations);
    });
  }
  
  queryCurrentMin() {
    const query = this.perimeters.createQuery();
    query.where = 'Simulation_ID = ' + this.simulationId;
    query.returnGeometry = false;
    query.outFields = ['ET_mins'];
    query.orderByFields = ['ET_mins DESC'];
    this.perimeters.queryFeatures(query).then(results => {
      if (results.features && results.features.length > 0) {
        this.curMinute = results.features[0].attributes.ET_mins;
        if (this.curMinute && this.curMinute > this.slider.max) {
          this.slider.max = this.curMinute;
        }
        if (this.liveMode) {
          this.perimeters.refresh();
          this.firePoints.refresh();
          this.filterPerimeters(this.curMinute);
        }
        console.log(this.curMinute);
      }
    }).catch(error => {
      console.log(error);
    })
  }

  filterPerimeters(minute) {
    this.sliderMinute = minute;
    const curMin = (this.curMinute && minute >= this.curMinute) ? JSON.stringify(this.curMinute) : JSON.stringify(minute);
    this.perimetersView.filter = {
      where: 'ET_mins = ' + curMin,
    }
    this.firePointsView.filter = {
      where: 'ET_mins = ' + curMin,
    }
    if (this.liveMode) {
      this.aircraftLocView.filter = {
        where: 'currentAC = 1'
      }
      this.aircraftBreadCrumbView.filter = {
        where: 'currentAC = 0'
      }
    } else {
      this.aircraftLocView.filter = {
        where: 'ET_mins = ' + curMin
      }
      this.aircraftBreadCrumbView.filter = {
        where: 'ET_mins < ' + curMin
      }
    }
    this.elapsedMinute = curMin;
    this.getAcreage(curMin);
  }

  // updateBreadCrumb(acLayerView) {
  //   const breadCrumbSymbol = {
  //     type: "simple-marker",
  //     outline: { width: 0 },
  //     size: 6.5,
  //     color: [121, 243, 71, 1]
  //   };
  //   acLayerView.queryFeatures().then(acFS => {
  //     const graphics = acFS.features.map(feature => {
  //       feature.symbol = breadCrumbSymbol;
  //       return feature;
  //     })
  //     // this.aircraftGraphics.addMany(graphics);
  //   })
  // }

  getAcreage(elapsedMin){
    this.perimeters
      .queryFeatures({
        //geometry: this.view.extent,
        returnGeometry: true,
        where: 'ET_mins = ' + elapsedMin + ' AND Simulation_ID = ' + this.simulationId,
        outFields: ['ET_mins', 'Simulation_ID', 'Shape__Area']
      })
      .then(results => {
        // do something with the resulting graphics
        this.acreage = 0;
        const graphics: Array<any> = results.features;
        if (graphics) {
          graphics.forEach(graphic => {
            if (graphic.attributes.Shape__Area > 0) {
              let acres = geometryEngine.geodesicArea(graphic.geometry, 'acres');
              this.acreage = (this.acreage < acres) ? acres : this.acreage;
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.acreage = 0
      });
  }
  
  toggleFuelModel() {
    this.airspaceMapVisible = false;
    this.airspaceMapLayer.visible = this.airspaceMapVisible;
    this.fbfm40Visible = !this.fbfm40Visible;
    this.fbfm40Layer.visible = this.fbfm40Visible;
  }

  toggleAirspaceMap() {
    this.fbfm40Visible = false;
    this.fbfm40Layer.visible = this.fbfm40Visible;
    this.airspaceMapVisible = !this.airspaceMapVisible;
    this.airspaceMapLayer.visible = this.airspaceMapVisible;
  }

  switchView(viewer: string) {
    console.log(viewer);
    this.viewer = viewer;
    switch (viewer) {
      case 'facilitator':
        this.liveMode = false;
        this.sliderHidden = false;
        this.fbfm40Hidden = false;
        this.filterPerimeters(this.curMinute);
        this.slider.values = [this.curMinute];
        this.mapConfigService.mapConfig.activeView.ui.add(this.expandWidget, 'top-left');
        break;
      
      case 'incident-commander':
        this.liveMode = true;
        this.sliderHidden = true;
        this.fbfm40Hidden = false;
        this.queryCurrentMin();
        // this.aircraftLocView.filter = "currentAC = 1";
        this.mapConfigService.mapConfig.activeView.ui.add(this.expandWidget, 'top-left');
        break;

      case 'observer':
        this.liveMode = true;
        this.sliderHidden = true;
        this.fbfm40Hidden = true;
        this.fbfm40Visible = false;
        this.fbfm40Layer.visible = false;
        this.queryCurrentMin();
        // this.aircraftLocView.filter = "currentAC = 1";
        this.mapConfigService.mapConfig.activeView.ui.remove(this.expandWidget);
        break;
    
      default:
        break;
    }
  }

  ngOnInit(): any {

    // Required: Set this property to insure assets resolve correctly.
    // IMPORTANT: the directory path may be different between your production app and your dev app
    //esriConfig.assetsPath = './assets';

    // Initialize MapView and return an instance of MapView
    this.initializeMap().then(() => {
      this.openDialog();
      // The map has been initialized
      console.log('mapView ready: ');
    });

  }

  ngOnDestroy(): void {
    if (this.view) {
      // destroy the map view
      this.view.destroy();
    }
  }
}
