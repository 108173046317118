<style>
    :host {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  </style>
<div class="header esri-widget">
  <div class="title">
    <img src="assets/images/logo.png" alt="BeckerSupportIcon" class="icon">
    <span>Interactive Map Interface</span>
  </div>
  <mat-button-toggle-group name="view-toggle" (change)=switchView($event.value)>
    <mat-button-toggle checked value="facilitator">Facilitator</mat-button-toggle>
    <mat-button-toggle value="incident-commander">Incident Commander</mat-button-toggle>
    <mat-button-toggle value="observer">Observer</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<!-- Map Div -->
<div #mapViewNode></div>
<!-- Slider Div -->
<div #sliderContainerNode class="esri-widget slider-container" [ngClass]="{'hidden': sliderHidden}">
  <div #sliderNode class="slider"></div>
</div>
<div #view3DSwitchNode class="esri-component esri-widget--button esri-widget" (click)="mapConfigService.switchMapView()">{{viewTypeLabel}}</div>
<!-- <div #view2DSwitchNode class="esri-component esri-widget--button esri-widget" (click)="mapConfigService.switchMapView()">2D</div> -->
<!-- Fuel Model Div -->
<button #fbfm40Node class="esri-widget layer fbfm40-button" [ngClass]="{'active': fbfm40Visible, 'hidden': fbfm40Hidden}" (click)="toggleFuelModel()">Fuel Model</button>
<!--Airspace Map Div-->
<button #airspaceMapNode class="esri-widget layer airspace-button" [ngClass]="{'active': airspaceMapVisible, 'hidden': airspaceMapHidden}" (click)="toggleAirspaceMap()">
  <div class="button-text">FAA</div></button>
<!-- Statistics Div -->
<div #statisticsNode class="stats">
  <div>Elapsed Time (min): {{elapsedMinute}}</div>
  <div>Acreage: {{acreage | number: '1.0-2'}}</div>
</div>

